import { makeAutoObservable } from "mobx";
import { Listing } from "app-ts-types/listings";
import {listings} from "./data"


class ListingsStore {
	listings: Listing[] = listings;
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "" };
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedCategory: string | "" = "";
	selectedStatus: string | "" = "";
	selectedPlatform: string | "" = "";

	constructor() {
		makeAutoObservable(this);
	}

	setListings(listings: Listing[]) {
		this.listings = listings;
	}

	addListings(newItems: Listing[]) {
		this.listings = [...this.listings, ...newItems];
	}

	updateListing(updatedItem: Listing) {
		const index = this.listings.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.listings[index] = updatedItem;
		}
	}

	deleteListing(itemId: string) {
		this.listings = this.listings.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedCategory(category: string | "") {
		this.selectedCategory = category;
	}

	setSelectedStatus(status: string | "") {
		this.selectedStatus = status;
	}

	setSelectedPlatform(platform: string | "") {
		this.selectedPlatform = platform;
	}

	get filteredAndSortedListings(): Listing[] {
		let filteredListings = this.listings;

		if (this.filter) {
			filteredListings = filteredListings.filter(item =>
				item.title.includes(this.filter)
			);
		}

		if (this.selectedCategory !== "") {
			filteredListings = filteredListings.filter(item =>
				item.category === this.selectedCategory
			);
		}

		if (this.selectedStatus !== "") {
			filteredListings = filteredListings.filter(item =>
				item.status === this.selectedStatus
			);
		}

		if (this.selectedPlatform !== "") {
			filteredListings = filteredListings.filter(item =>
				item.platform.toString() === this.selectedPlatform
			);
		}

		if (this.sortOption.field) {
			filteredListings = filteredListings.sort((a, b) => {
				if (this.sortOption.direction === "ASCENDING") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredListings;
	}

	get paginatedListings(): Listing[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedListings.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedListings.length / this.itemsPerPage);
	}
}

export const listingsStore = new ListingsStore();
