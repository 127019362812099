export enum MarketplacePlatform {
	EBAY = 0,
	GRAILED = 1,
	MERCARI = 2,
	SHOPIFY = 3,
	STOCKX = 4,
	LIGHTSPEED = 5,
	GOAT = 6,
	AMAZON = 7,
	ETSY = 8,
	UNRECOGNIZED = -1,
}

export function marketplacePlatformFromJSON(object: any): MarketplacePlatform {
	switch (object) {
		case 0:
		case "EBAY":
			return MarketplacePlatform.EBAY;
		case 1:
		case "GRAILED":
			return MarketplacePlatform.GRAILED;
		case 2:
		case "MERCARI":
			return MarketplacePlatform.MERCARI;
		case 3:
		case "SHOPIFY":
			return MarketplacePlatform.SHOPIFY;
		case 4:
		case "STOCKX":
			return MarketplacePlatform.STOCKX;
		case 5:
		case "LIGHTSPEED":
			return MarketplacePlatform.LIGHTSPEED;
		case 6:
		case "GOAT":
			return MarketplacePlatform.GOAT;
		case 7:
		case "AMAZON":
			return MarketplacePlatform.AMAZON;
		case 8:
		case "ETSY":
			return MarketplacePlatform.ETSY;
		case -1:
		case "UNRECOGNIZED":
		default:
			return MarketplacePlatform.UNRECOGNIZED;
	}
}

export function marketplacePlatformToJSON(object: MarketplacePlatform): string {
	switch (object) {
		case MarketplacePlatform.EBAY:
			return "EBAY";
		case MarketplacePlatform.GRAILED:
			return "GRAILED";
		case MarketplacePlatform.MERCARI:
			return "MERCARI";
		case MarketplacePlatform.SHOPIFY:
			return "SHOPIFY";
		case MarketplacePlatform.STOCKX:
			return "STOCKX";
		case MarketplacePlatform.LIGHTSPEED:
			return "LIGHTSPEED";
		case MarketplacePlatform.GOAT:
			return "GOAT";
		case MarketplacePlatform.AMAZON:
			return "AMAZON";
		case MarketplacePlatform.ETSY:
			return "ETSY";
		case MarketplacePlatform.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

export interface Platform {
	id: MarketplacePlatform;
	name: string;
	url: string;
	homepage: string;
	supportsApi: boolean;
	instructionSet: string;
}

export interface Collection {
	/** the id of the collection */
	id: string;
	/** the user of this collection */
	user: string;
	/** the name of this collection */
	name: string;
	/** is this collection listed? */
	listed: boolean;
	/** automatically list this collection including any new additions */
	autoList: boolean;
	/** schedule this listing on a specific day */
	scheduleListing: number;
	/** Timestamp indicating when the collection was created */
	createdAt: number;
	/** when the collection was updated */
	updatedAt: number;
}

/** Enum to represent different status */
export enum Statuses {
	UNLISTED = 0,
	PERSONAL = 1,
	IN_TRANSIT = 2,
	DELIVERED = 3,
	CONSIGNED = 4,
	LISTED = 5,
	UNRECOGNIZED = -1,
}

export function statusesFromJSON(object: any): Statuses {
	switch (object) {
		case 0:
		case "UNLISTED":
			return Statuses.UNLISTED;
		case 1:
		case "PERSONAL":
			return Statuses.PERSONAL;
		case 2:
		case "IN_TRANSIT":
			return Statuses.IN_TRANSIT;
		case 3:
		case "DELIVERED":
			return Statuses.DELIVERED;
		case 4:
		case "CONSIGNED":
			return Statuses.CONSIGNED;
		case 5:
		case "LISTED":
			return Statuses.LISTED;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Statuses.UNRECOGNIZED;
	}
}

export function statusesToJSON(object: Statuses): string {
	switch (object) {
		case Statuses.UNLISTED:
			return "UNLISTED";
		case Statuses.PERSONAL:
			return "PERSONAL";
		case Statuses.IN_TRANSIT:
			return "IN_TRANSIT";
		case Statuses.DELIVERED:
			return "DELIVERED";
		case Statuses.CONSIGNED:
			return "CONSIGNED";
		case Statuses.LISTED:
			return "LISTED";
		case Statuses.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different sources for the inventory products */
export enum Sources {
	EMAIL = 0,
	SPREADSHEET = 1,
	UNRECOGNIZED = -1,
}

export function sourcesFromJSON(object: any): Sources {
	switch (object) {
		case 0:
		case "EMAIL":
			return Sources.EMAIL;
		case 1:
		case "SPREADSHEET":
			return Sources.SPREADSHEET;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Sources.UNRECOGNIZED;
	}
}

export function sourcesToJSON(object: Sources): string {
	switch (object) {
		case Sources.EMAIL:
			return "EMAIL";
		case Sources.SPREADSHEET:
			return "SPREADSHEET";
		case Sources.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different categories for the products */
export enum Categories {
	SNEAKERS = 0,
	STREET_WEAR = 1,
	COLLECTIBLES = 2,
	HANDBAGS = 3,
	TOPS = 4,
	WATCHES = 5,
	NFT = 6,
	SHORTS = 7,
	PANTS = 8,
	UNRECOGNIZED = -1,
}

export function categoriesFromJSON(object: any): Categories {
	switch (object) {
		case 0:
		case "SNEAKERS":
			return Categories.SNEAKERS;
		case 1:
		case "STREET_WEAR":
			return Categories.STREET_WEAR;
		case 2:
		case "COLLECTIBLES":
			return Categories.COLLECTIBLES;
		case 3:
		case "HANDBAGS":
			return Categories.HANDBAGS;
		case 4:
		case "TOPS":
			return Categories.TOPS;
		case 5:
		case "WATCHES":
			return Categories.WATCHES;
		case 6:
		case "NFT":
			return Categories.NFT;
		case 7:
		case "SHORTS":
			return Categories.SHORTS;
		case 8:
		case "PANTS":
			return Categories.PANTS;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Categories.UNRECOGNIZED;
	}
}

export function categoriesToJSON(object: Categories): string {
	switch (object) {
		case Categories.SNEAKERS:
			return "SNEAKERS";
		case Categories.STREET_WEAR:
			return "STREET_WEAR";
		case Categories.COLLECTIBLES:
			return "COLLECTIBLES";
		case Categories.HANDBAGS:
			return "HANDBAGS";
		case Categories.TOPS:
			return "TOPS";
		case Categories.WATCHES:
			return "WATCHES";
		case Categories.NFT:
			return "NFT";
		case Categories.SHORTS:
			return "SHORTS";
		case Categories.PANTS:
			return "PANTS";
		case Categories.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different stock levels */
export enum StockLevel {
	LOW = 0,
	NORMAL = 1,
	HIGH = 2,
	UNRECOGNIZED = -1,
}

export function stockLevelFromJSON(object: any): StockLevel {
	switch (object) {
		case 0:
		case "LOW":
			return StockLevel.LOW;
		case 1:
		case "NORMAL":
			return StockLevel.NORMAL;
		case 2:
		case "HIGH":
			return StockLevel.HIGH;
		case -1:
		case "UNRECOGNIZED":
		default:
			return StockLevel.UNRECOGNIZED;
	}
}

export function stockLevelToJSON(object: StockLevel): string {
	switch (object) {
		case StockLevel.LOW:
			return "LOW";
		case StockLevel.NORMAL:
			return "NORMAL";
		case StockLevel.HIGH:
			return "HIGH";
		case StockLevel.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/**
 * this is the size and the quantity per size field
 * associated with a specific inventory.
 * each inventory can have a multiple sizings
 */
export interface QuantitySizing {
	/** the size of the product */
	size: string;
	/** in how many quantities does this size exist */
	quantity: number;
}

export interface Inventory {
	/** Basic Info */
	name: string;
	SKU: string;
	brand: string;
	color: string;
	totalQuantity: number;
	description: string;
	/** the sizes listed in the inventory */
	sizes: QuantitySizing[];
	/** Purchase Details */
	purchaseDetails:
		| PurchaseDetails
		| undefined;
	/** Classification Details */
	category: Categories;
	status: Statuses;
	source: Sources;
	platform: MarketplacePlatform;
	deleted: boolean;
	createdAt: number;
	updatedAt: number;
	/** create a collection given the name */
	collectionId: string;
	outOfStock: boolean;
	/** the level the stock is at */
	stockLevel: StockLevel;
	/** the id and the user of the inventory object */
	id: string;
	user: string;
	imageUrl: string;
	scheduleListing: number;
	autoList: boolean;
}

export interface PurchaseDetails {
	price: number;
	shipping: string;
	vatPercentage: number;
	purchasePlace: string;
	/** Consider using a date or timestamp type */
	purchaseDate: string;
	orderNumber: string;
}

export interface Listing {
	/** Unique identifier for the listing */
	id: string;
	/** who does this listing belong to */
	user: string;
	/** Title of the product listing */
	title: string;
	/** Description of the product listing */
	description: string;
	/** Price of the product listing */
	price: number;
	/** Currency code (e.g., USD, EUR) */
	currencyCode: string;
	/** Quantity available for sale */
	quantity: number;
	/** URL of the product image */
	imageUrl: string;
	/** Category of the product listing */
	category: string;
	/** Condition of the product (e.g., new, used) */
	condition: string;
	/** Seller username or ID */
	seller: string;
	/** Location of the item (e.g., city, country) */
	location: string;
	/** Shipping information */
	shipping:
		| Shipping
		| undefined;
	/** where this listing is taking place */
	platform: MarketplacePlatform;
	/** if this listing is scheduled */
	scheduleDateTime: number;
	/** the createdAt for the listings */
	createdAt: number;
	/** the updatedAt for the */
	updatedAt: number;
	/** the status of this listing */
	status: string;
	/** the inventory id */
	inventoryId: string;
}

export interface Shipping {
	/** Shipping cost */
	cost: number;
	/** Estimated delivery time (in days) */
	estimatedDeliveryTime: number;
	/** Shipping method (e.g., standard, expedited) */
	method: string;
}
