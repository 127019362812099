import React from 'react';
import {useState} from 'react'
// @ts-ignore
import * as XLSX from "xlsx";
import {Link, Route, Routes} from "react-router-dom";
import {PlansAndAddonsComponent} from "./Profile";
import {MarketplacePlatform} from '../utils/types';
import PlatformEmailUsernamePasswordConfig from "../components/PlatformConfig"; // Adjust the import path as needed
import {Menu, MenuButton, MenuItem, MenuItems, Switch} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/24/solid';
import {Transition} from '@headlessui/react';
import {userStore} from "../state/User";
import {User} from "app-ts-types/users";
import {updateUser} from "../api/user";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {importInventory} from "../api/import-export";
import {inventoryStore} from "../state/Inventory";

const platforms = [
	{id: MarketplacePlatform.GRAILED, name: 'Grailed'},
	{id: MarketplacePlatform.MERCARI, name: 'Mercari'},
	{id: MarketplacePlatform.LIGHTSPEED, name: 'Lightspeed'},
	{id: MarketplacePlatform.GOAT, name: 'GOAT'},
	{id: MarketplacePlatform.ETSY, name: 'Etsy'},
];

const secondaryNavigation = [
	{name: 'General', href: '/settings'},
	{name: 'Plans & Addons', href: '/settings/addons'},
	// {name: 'Notifications', href: '/settings/notification'},
	{name: 'Platform Configurations', href: '/settings/platform-config'},
]

export default function SettingsPage() {
	const [name, setName] = useState("General");
	const [loading, setLoading] = useState({personalInfo: false});
	const [loadExport, setLoadExport] = useState(false);
	const [loadImport, setLoadImport] = useState(false);
	const [showExportDialog, setShowExportDialog] = useState(false);
	const [loadNotification, setLoadNotification] = useState(false);
	const [selectedFormat, setSelectedFormat] = useState('Select Format');
	const [selectedFields, setSelectedFields] = useState<string[]>([]);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleFormatChange = (format: string) => {
		setSelectedFormat(format);
	};

	const handleFieldToggle = (field: string) => {
		setSelectedFields((prevFields) =>
			prevFields.includes(field)
				? prevFields.filter((f) => f !== field)
				: [...prevFields, field]
		);
	};

	const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setLoadImport(true);
		const file = event.target.files?.[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			try {
				const response = await importInventory(formData)
				console.log(response);
				if (response.success) {
					inventoryStore.addInventory(response.data);
					setNotificationObject({
						title: "Import Success",
						message: "New inventory objects imported successfully",
						isError: false,
						show: true,
						autoClose: true,
					});
				} else {
					setNotificationObject({
						title: "Import Error",
						message: response.error || "Error importing inventory objects. please check your file",
						isError: true,
						show: true,
						autoClose: true,
					});
				}
			} catch (error:any) {
				console.error('Error importing inventory:', error);
				setNotificationObject({
					title: "Import Error",
					message: error.toString() || "Error importing inventory objects. please check your file",
					isError: true,
					show: true,
					autoClose: true,
				});
			} finally {
				setLoadImport(false);
				event.target.value = '';
			}
		} else {
			setLoadImport(false);
		}
	};

	const handleExport = (event: any) => {
		event.preventDefault();
		setLoadExport(true);
		// Simulate an API call or data processing
		setTimeout(() => {
			// Logic to export data based on selected format and fields
			console.log('Exporting data in format:', selectedFormat);
			console.log('Selected fields:', selectedFields);

			// Reset loading state and close the dialog
			setLoadExport(false);
			setShowExportDialog(false);
		}, 2000);
	};

	const handleNotificationSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoadNotification(true);
		const formData = new FormData(event.currentTarget);
		const notificationTypes = {
			webhook: formData.get('webhook') === 'on',
			email: formData.get('email') === 'on',
			browser: formData.get('browser') === 'on',
		};
		const notificationEvents = {
			systemError: formData.get('systemError') === 'on',
			orderProcessing: formData.get('orderProcessing') === 'on',
			newListing: formData.get('newListing') === 'on',
			priceChange: formData.get('priceChange') === 'on',
			lowStock: formData.get('lowStock') === 'on',
			returnOrCancellation: formData.get('returnOrCancellation') === 'on',
		};
		const data = {notificationTypes, notificationEvents};
		// Call your API function here
		await updateUser(userStore.user!.id, {...userStore.getUser(), notificationSettings: data} as User)
			.then((res: {[key: string]: any}) => {
				if(!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), notificationSettings: data}))
					userStore.setUser({...userStore.getUser(), notificationSettings: data} as User)
					setNotificationObject({...notificationObject, show: true, isError: false, title: "User updated", message: "User information successfully updated"})
				} else {
					// @ts-ignore
					setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: res.error?.errorMessage | res.message })
				}
			})
			.catch((err:any) => {
				console.log(err);
				setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: err.message})
			})
		setLoadNotification(false);
	};

	const handlePersonalInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, personalInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		// Call your API function here
		await updateUser(userStore.user!.id, {...userStore.getUser(), ...data} as User)
			.then((res:any) => {
				if(!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify({...userStore.getUser(), ...data}))
					userStore.setUser({...userStore.getUser(), ...data} as User)
					setNotificationObject({...notificationObject, show: true, isError: false, title: "User updated", message: "User information successfully updated"})
				} else {
					// @ts-ignore
					setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: res.error?.errorMessage | res.message})
				}
			})
			.catch((err:any) => {
				console.log(err);
				setNotificationObject({...notificationObject, show: true, isError: true, title: "Error updating user object", message: err.message})
			})
		// Call your API function here
		setLoading((prev) => ({...prev, personalInfo: false}));
	};

	const handleSubmit = (platform: any) => (formData: { email: string; password: string }) => {
		console.log(`Submitting data for ${platform}:`, formData);
		// Add your submission logic here
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	const mainComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		{/* Personal Information */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Personal Information</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Use a permanent address where you can receive mail.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handlePersonalInfoSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="sm:col-span-3">
						<label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							First name
						</label>
						<div className="mt-2">
							<input
								id="firstName"
								name="firstName"
								type="text"
								defaultValue={userStore.user?.firstName}
								autoComplete="first-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Last name
						</label>
						<div className="mt-2">
							<input
								id="lastName"
								name="lastName"
								type="text"
								defaultValue={userStore.user?.lastName}
								autoComplete="last-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								defaultValue={userStore.user?.email}
								autoComplete="email"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Username
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="username"
									name="username"
									type="text"
									defaultValue={userStore.user?.username}
									placeholder="janesmith"
									autoComplete="username"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="discordChannelUrl"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Discord Channel Webhook
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="discordChannelUrl"
									name="discordChannelUrl"
									type="text"
									defaultValue={userStore.user?.discordChannelUrl}
									placeholder="Discord Channel Webhook"
									autoComplete="discordChannelUrl"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.personalInfo}
					>
						{loading.personalInfo ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Notification Configuration</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Configure the types of notifications and events you want to be notified about.
				</p>
			</div>

			<form className="md:col-span-2 space-y-6" onSubmit={handleNotificationSubmit}>
				<div className="space-y-4">
					<h3 className="text-lg font-medium text-gray-900 dark:text-white">Notification Types</h3>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="webhook" name="webhook" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.webhook}
							       className="h-4 w-4 cursor-pointer text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="webhook" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Webhook</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="emailNot" name="email" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.email}
							       className="h-4 w-4 cursor-pointer text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="email" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Email</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="browser" name="browser" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationTypes?.browser}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="browser" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Browser Desktop Notification</label>
						</div>
					</div>
				</div>

				<div className="space-y-4">
					<h3 className="text-lg font-medium text-gray-900 dark:text-white">Notification Events</h3>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="systemError" name="systemError" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.systemError}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="systemError" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">System Error</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="orderProcessing" name="orderProcessing" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.orderProcessing}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="orderProcessing" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Order
								Processing</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="newListing" name="newListing" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.newListing}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="newListing" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">New Listing</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="priceChange" name="priceChange" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.priceChange}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="priceChange" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Price Change</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="lowStock" name="lowStock" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.lowStock}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="lowStock" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Low Stock Level
								Alerts</label>
						</div>
					</div>
					<div className="flex items-start">
						<div className="flex items-center h-5">
							<input id="returnOrCancellation" name="returnOrCancellation" type="checkbox" defaultChecked={userStore.user?.notificationSettings?.notificationEvents?.returnOrCancellation}
							       className="h-4 w-4 text-indigo-600 cursor-pointer border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"/>
						</div>
						<div className="ml-3 text-sm">
							<label htmlFor="returnOrCancellation" className="font-medium cursor-pointer text-gray-700 dark:text-gray-300">Return or
								Cancellation</label>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loadNotification}
					>
						{loadNotification ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>

		{/* Import/Export Section */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Import Data</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Import your inventory data from an Excel file.
				</p>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					The Excel format should be: SKU, Product Name, Size, Price, Quantity, Description, Category.
				</p>
			</div>

			<div className="md:col-span-2 space-y-4">
				<div className="">
					<label htmlFor="import-file"
					       className="cursor-pointer inline-block rounded-md bg-indigo-500 px-3 py-2 text-xl font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
						{loadImport ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Import Data'
						)}
					</label>
					<input
						type="file"
						accept=".xlsx"
						onChange={handleImport}
						className="hidden"
						id="import-file"
					/>
				</div>
			</div>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Export Data</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Export your current inventory data.
				</p>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					The Excel format should be: SKU, Product Name, Size, Price, Quantity, Description, Category.
				</p>
			</div>

			<div className="md:col-span-2 space-y-4">
				<div className="flex items-center space-x-4">
					<button
						onClick={() => setShowExportDialog(true)}
						className="rounded-md text-xl bg-indigo-500 px-3 py-2 font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loadExport}
					>
						Export Data
					</button>
				</div>
			</div>
		</div>

		{/* Export Dialog */}
		{showExportDialog && (
			<div className="fixed inset-0 z-50 overflow-y-auto">
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<div className="fixed inset-0 transition-opacity" aria-hidden="true">
						<div className="absolute inset-0 bg-gray-500 opacity-75"></div>
					</div>
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">​</span>
					<div
						className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
						<div>
							<h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">Export Inventory</h3>
							<div className="mt-2">
								<p className="text-sm text-gray-500 dark:text-gray-400">Select the format and fields you want to
									export.</p>
							</div>
							<div className="mt-4">
								<label htmlFor="export-format"
								       className="block text-sm font-medium text-gray-700 dark:text-gray-300">Format</label>
								<Menu as="div" className="relative inline-block text-left w-full">
									<div>
										<Menu.Button
											className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											{selectedFormat}
											<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
										</Menu.Button>
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute left-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<div className="py-1">
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('CSV')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															CSV
														</button>
													)}
												</Menu.Item>
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('Excel')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															Excel
														</button>
													)}
												</Menu.Item>
												<Menu.Item>
													{({active}) => (
														<button
															type="button"
															onClick={() => handleFormatChange('JSON')}
															className={`${
																active ? 'bg-gray-100 dark:bg-gray-700' : ''
															} block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300`}
														>
															JSON
														</button>
													)}
												</Menu.Item>
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
							<div className="mt-4">
								<label htmlFor="fields"
								       className="block text-sm font-medium text-gray-700 dark:text-gray-300">Fields</label>
								<Menu as="div" className="relative inline-block text-left w-full">
									<div>
										<Menu.Button
											className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											Select Fields
											<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
										</Menu.Button>
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="left-0 z-[100] mt-2 w-full origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
											<div className="py-1 max-h-80">
												{['SKU', 'Product Name', 'Size', 'Price', 'Quantity', 'Description', 'Category'].map((field) => (
													<Menu.Item key={field}>
														{({active}) => (
															<div className="flex items-center px-4 py-2">
																<input
																	id={field}
																	name={field}
																	type="checkbox"
																	checked={selectedFields.includes(field)}
																	onChange={() => handleFieldToggle(field)}
																	className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600"
																/>
																<label htmlFor={field}
																       className="ml-3 block text-sm font-medium text-gray-700 dark:text-gray-300">
																	{field}
																</label>
															</div>
														)}
													</Menu.Item>
												))}
											</div>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>

						</div>
						<div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
							<button
								type="button"
								className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
								onClick={handleExport}
								disabled={loadExport}
							>
								{loadExport ? (
									<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
									     viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
										        strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor"
										      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
									</svg>
								) : (
									"Export"
								)}
							</button>
							<button
								type="button"
								className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
								onClick={() => setShowExportDialog(false)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</div>
		)}

		{ notificationComponent }
	</div>
	const notificationSettings = <main className="mx-auto max-w-lg px-4 pb-12 pt-10 lg:pb-16">
		Notifications
	</main>
	const platformConfigSettings = <div
		className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
		<div>
			<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Connect 3rd party
				Accounts</h2>
			<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
				Connect your marketplace accounts for email tracking and inventory listing.
			</p>
		</div>
		<div className="md:col-span-2">
			<div className="grid grid-cols-4 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
				<div className="col-span-1">
					<a href="https://api.tracknlist.com/api/google/login" className="block w-full">
						<svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 hover:opacity-80">
							<path
								d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
								fill="#EA4335"></path>
							<path
								d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
								fill="#4285F4"></path>
							<path
								d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
								fill="#FBBC05"></path>
							<path
								d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
								fill="#34A853"></path>
						</svg>
					</a>
				</div>
				<div className="col-span-1">
					<a href="https://api.tracknlist.com/api/ebay/login" className="block w-full">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 border p-0.5 rounded hover:opacity-80"
						     viewBox="0 0 24 24"
						     id="ebay">
							<path fill="#E53238"
							      d="M3.103 9.29C1.411 9.29 0 10.008 0 12.175c0 1.717.949 2.797 3.147 2.797 2.588 0 2.754-1.705 2.754-1.705H4.647s-.269.918-1.576.918c-1.065 0-1.831-.719-1.831-1.728h4.792v-.633c0-.996-.633-2.534-2.929-2.534zm-.043.809c1.014 0 1.705.621 1.705 1.552H1.268c0-.989.902-1.552 1.792-1.552z"></path>
							<path fill="#0064D2"
							      d="M6.031 7.192v6.692c0 .38-.027.913-.027.913H7.2s.043-.383.043-.733c0 0 .591.924 2.198.924 1.692 0 2.841-1.175 2.841-2.858 0-1.566-1.056-2.825-2.838-2.825-1.669 0-2.188.901-2.188.901V7.192H6.031zm3.103 2.942c1.149 0 1.88.853 1.88 1.997 0 1.227-.844 2.03-1.871 2.03-1.226 0-1.888-.958-1.888-2.019 0-.989.593-2.008 1.879-2.008z"></path>
							<path fill="#F5AF02"
							      d="M15.254 9.29c-2.547 0-2.71 1.394-2.71 1.617h1.268s.066-.814 1.355-.814c.837 0 1.486.383 1.486 1.12v.262h-1.486c-1.973 0-3.016.577-3.016 1.748 0 1.153.964 1.78 2.266 1.78 1.775 0 2.347-.981 2.347-.981 0 .39.03.775.03.775h1.127s-.044-.476-.044-.781v-2.635c-.001-1.728-1.394-2.091-2.623-2.091zm1.398 2.972v.35c0 .456-.281 1.59-1.938 1.59-.907 0-1.296-.453-1.296-.978 0-.956 1.31-.962 3.234-.962z"></path>
							<path fill="#86B817"
							      d="M17.194 9.509h1.426l2.047 4.1 2.042-4.1H24l-3.719 7.3h-1.355l1.073-2.035-2.805-5.265z"></path>
						</svg>
					</a>
				</div>
				<div className="col-span-1">
					<a href="https://api.tracknlist.com/api/amazon/login" className="block w-full">
						<svg xmlns="http://www.w3.org/2000/svg" className={"h-6 w-6 hover:opacity-80"} viewBox="0 0 48 48"
						     id="amazon">
							<g fill="none" fill-rule="evenodd">
								<g transform="translate(-601 -560)">
									<g transform="translate(601 560)">
										<path fill="#343B45"
										      d="M25.403 25.96c-.743 1.482-2.015 2.436-3.393 2.758-.208 0-.527.105-.846.105-2.329 0-3.706-1.802-3.706-4.45 0-3.394 2.012-4.981 4.552-5.726 1.378-.317 2.97-.424 4.558-.424v1.273c0 2.437.105 4.343-1.165 6.464zm1.165-12.608c-1.377.105-2.969.21-4.558.418-2.435.322-4.87.746-6.88 1.7-3.92 1.59-6.57 4.98-6.57 9.959 0 6.257 4.024 9.433 9.113 9.433 1.693 0 									3.07-.214 4.337-.528 2.018-.638 3.709-1.804 5.721-3.925 1.166 1.59 1.487 2.335 3.497 4.03.53.209 1.06.209 1.481-.105 1.273-1.062 3.5-2.97 4.663-4.03.53-.423.426-1.06.104-1.586-1.163-1.485-2.331-2.758-2.331-5.619v-9.538c0-4.026.322-7.736-2.645-10.489C30.065.85 26.25 0 23.283 0H22.01C16.612.313 10.894 2.646 9.618 9.323c-.212.85.426 1.166.85 1.27l5.932.743c.635-.107.954-.638 1.058-1.163.528-2.332 2.436-3.498 4.552-3.713h.427c1.272 0 2.65.531 3.389 1.593.847 1.27.742 2.967.742 4.452v.847z"></path>
										<path fill="#FF9A00"
										      d="M47.994 35.946v-.002c-.022-.5-.127-.881-.335-1.198l-.023-.03-.025-.032c-.212-.231-.415-.319-.635-.415-.658-.254-1.615-.39-2.766-.392-.827 0-1.739.079-2.656.28l-.003-.063-.923.308-.017.008-.522.17v.022a8.17 8.17 0 0 0-1.684.946c-.322.24-.587.56-.602 1.048a.978.978 0 0 0 .35.75 1.119 1.119 0 0 0 .861.232l.045-.002.034-.006c.452-.096 1.11-.161 1.88-.268.66-.074 1.36-.127 1.967-.127.429-.003.815.028 1.08.084a1.208 1.208 0 0 1 .328.11.955.955 0 0 1 .025.266c.006.508-.208 1.451-.505 2.372-.288.92-.638 1.843-.869 2.456a1.246 1.246 0 0 0-.093.466c-.006.246.096.545.31.743.21.197.48.276.706.276h.011c.339-.003.627-.138.875-.333 2.343-2.106 3.158-5.472 3.192-7.367l-.006-.302zm-6.945 2.92a1.645 1.645 0 0 0-.714.16c-.257.102-.52.221-.768.326l-.364.152-.474.19v.005c-5.15 2.09-10.56 3.315-15.567 3.422-.184.006-.37.006-.548.006-7.874.005-14.297-3.648-20.777-7.248a1.482 1.482 0 0 0-.685-.181c-.291 0-.59.11-.808.313a1.108 1.108 0 0 0-.344.805c-.003.392.209.754.505.988C6.587 43.087 13.253 47.994 22.22 48c.175 0 .353-.006.53-.008 5.704-.128 12.153-2.056 17.16-5.201l.03-.02a17.54 17.54 0 0 0 1.928-1.333c.384-.285.65-.731.65-1.194-.017-.822-.715-1.378-1.468-1.378z"></path>
									</g>
								</g>
							</g>
						</svg>
					</a>
				</div>
				<div className="col-span-1">
					<a href="https://api.tracknlist.com/api/shopify/login" className="block w-full">
						<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:opacity-80" fill="none"
						     viewBox="0 0 48 48" id="shopify">
							<path fill="#95BF47" fill-rule="evenodd"
							      d="M39.5162 8.96641C39.6859 8.9947 39.9122 9.10784 39.9405 9.3624C39.9574 9.51497 41.8335 22.2041 43.3343 32.3545C44.3377 39.1409 45.1732 44.7924 45.1732 44.8038L30.3518 48L3 42.8804C3 42.8804 6.50737 15.7266 6.64879 14.7366C6.8185 13.4638 6.87507 13.4355 8.20448 13.0112C8.3271 12.9805 8.88159 12.8087 9.74646 12.5407C10.4776 12.3141 11.4307 12.0187 12.5321 11.6818C13.4655 7.75015 16.4072 0.0282852 22.6017 0C23.3936 0 24.327 0.452563 25.0907 1.44255H25.317C27.9758 1.44255 29.5032 3.70536 30.3235 6.16618C31.2004 5.88332 31.7378 5.71361 31.7661 5.71361C31.9923 5.65704 32.5298 5.57219 32.8126 5.85504C33.0672 6.13789 35.6411 8.68356 35.6411 8.68356C35.6411 8.68356 39.3465 8.93813 39.5162 8.96641ZM27.1432 7.1642C27.7508 6.98404 28.319 6.81556 28.8244 6.64707C28.2587 4.86511 27.3253 3.30942 25.9393 3.11142C26.2787 4.07312 26.505 5.31767 26.505 6.84507V7.3542C26.7218 7.28918 26.9348 7.226 27.1432 7.1642ZM24.2705 3.42242C24.6382 4.38412 24.8928 5.74181 24.8928 7.58035V7.83491C23.3937 8.28746 21.7533 8.79656 20.1128 9.30567L20.1126 9.30574C21.046 5.79838 22.7714 4.07298 24.2705 3.42242ZM23.2239 1.95163C22.9694 1.78192 22.6865 1.69707 22.4319 1.69707C17.9912 1.69707 15.5021 7.52382 14.4272 11.0878C15.6152 10.72 16.9163 10.3241 18.2174 9.92806C19.1226 5.23272 21.244 2.88504 23.2239 1.95163Z"
							      clip-rule="evenodd"></path>
							<path fill="#5E8E3E"
							      d="M39.5162 8.96639C39.3465 8.9381 35.6411 8.68354 35.6411 8.68354C35.6411 8.68354 33.0672 6.13787 32.8126 5.85501C32.6995 5.74187 32.558 5.6853 32.4166 5.6853L30.3518 48L45.1732 44.8037C45.1732 44.8037 39.9688 9.61695 39.9405 9.39067C39.9122 9.1361 39.6859 8.99467 39.5162 8.96639Z"></path>
							<path fill="#fff"
							      d="M25.317 17.1691L23.4785 22.5999C23.4785 22.5999 21.8662 21.7513 19.9145 21.7513C17.0295 21.7513 16.888 23.5616 16.888 24.0142C16.888 26.5033 23.3653 27.4367 23.3653 33.2634C23.3653 37.8456 20.452 40.7873 16.5486 40.7873C11.8533 40.7873 9.44902 37.8739 9.44902 37.8739L10.6936 33.716C10.6936 33.716 13.1544 35.8374 15.2475 35.8374C16.6052 35.8374 17.1709 34.7625 17.1709 33.9705C17.1709 30.7177 11.8533 30.5763 11.8533 25.2587C11.8533 20.7896 15.0778 16.4337 21.5551 16.4337C24.1008 16.462 25.317 17.1691 25.317 17.1691Z"></path>
						</svg>
					</a>
				</div>
				<div className="col-span-1">
					<a href="https://api.tracknlist.com/api/shopify/login" className="block w-full">
						<img src={"https://upload.wikimedia.org/wikipedia/commons/5/58/StockX_logo.svg"} alt={"stockx image"}/>
					</a>
				</div>
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-8">
				{platforms.map((platform) => (
					<div key={platform.id} className="bg-gray-100 dark:bg-gray-800 shadow-md rounded-lg p-6">
						<PlatformEmailUsernamePasswordConfig header={platform.name} onSubmit={handleSubmit(platform.id)}/>
					</div>
				))}
			</div>
		</div>
	</div>

	const addonSettings = <PlansAndAddonsComponent/>
	return (
		<>
			<h1 className="sr-only">Settings</h1>
			<header className="border-b border-gray-300 dark:border-gray-700 border-white/5">
				{/* Secondary navigation */}
				<nav className="flex overflow-x-auto py-4">
					<ul
						role="list"
						className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
					>
						{secondaryNavigation.map((item) => (
							<li key={item.name}>
								<Link to={item.href} onClick={() => {
									setName(item.name)
								}} className={name === item.name ? 'text-indigo-400' : ''}>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<Routes>
				<Route path="/" element={mainComponent}/>
				<Route path="/notification" element={notificationSettings}/>
				<Route path="/platform-config" element={platformConfigSettings}/>
				<Route path="/addons" element={addonSettings}/>
			</Routes>
		</>
	)
}
