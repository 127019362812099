import React, {useEffect, useState} from 'react';
import {Menu, MenuButton, MenuItem, MenuItems, Switch} from '@headlessui/react';
import {ChevronDownIcon, XCircleIcon, PlusCircleIcon} from '@heroicons/react/24/solid';
import {Dialog, Transition} from '@headlessui/react';
import {CheckIcon} from '@heroicons/react/24/solid';
import {
	statusesFromJSON,
	Collection,
	Statuses,
	Sources,
	Categories,
	StockLevel,
	QuantitySizing,
	PurchaseDetails,
	Inventory
} from '../utils/types';
import {generateUUID, productSizes} from "../utils/data";
import {ChevronRightIcon, PhotoIcon} from "@heroicons/react/24/outline";
import {inventoryStore} from "../state/Inventory";
import {collectionsStore} from "../state/Collection";
import {userStore} from "../state/User";
import {createInventory, updateInventory} from "../api/inventories";
import {GetInventoryResponse} from "app-ts-types/inventories";
import {User} from "app-ts-types/users";
import {NotificationPopupComponent} from "./NotificationPopup";

const steps = [
	{id: '01', name: 'Basic Info'},
	{id: '02', name: 'Purchase Details'},
	{id: '03', name: 'Classification Info'},
];

const initialInventory: Inventory = {
	name: '',
	SKU: '',
	brand: '',
	color: '',
	totalQuantity: 0,
	description: '',
	sizes: [],
	purchaseDetails: {
		price: 0,
		shipping: '',
		vatPercentage: 0,
		purchasePlace: '',
		purchaseDate: '',
		orderNumber: '',
	},
	category: Categories.UNRECOGNIZED,
	status: Statuses.UNLISTED,
	source: Sources.UNRECOGNIZED,
	platform: -1, // Assuming MarketplacePlatform is an enum
	deleted: false,
	createdAt: Date.now(),
	updatedAt: Date.now(),
	collectionId: '',
	outOfStock: false,
	stockLevel: StockLevel.UNRECOGNIZED,
	imageUrl: "",
	id: '',
	user: '',
	scheduleListing: 0,
	autoList: false,
};

const defaultCategory = (name: string): Categories => {
	if (name.toLowerCase().includes('sneaker')) return Categories.SNEAKERS;
	if (name.toLowerCase().includes('shirt')) return Categories.TOPS;
	// Add more conditions as needed
	return Categories.UNRECOGNIZED;
};

const calculateTotalQuantity = (sizes: QuantitySizing[]): number => {
	return sizes.reduce((total, size) => total + size.quantity, 0);
};

const determineStockLevel = (totalQuantity: number): StockLevel => {
	if (totalQuantity < 10) return StockLevel.LOW;
	if (totalQuantity >= 10 && totalQuantity <= 50) return StockLevel.NORMAL;
	return StockLevel.HIGH;
};

const InventoryForm = ({initialData = initialInventory, onClose}: {
	initialData?: Inventory,
	onClose: () => void;
}) => {
	// if there's no size information available, then let's add one for default size selection
	if (!initialData.sizes || !initialData.sizes.length) {
		initialData.sizes = [{size: '', quantity: 0}]
	}
	const [inventory, setInventory] = useState<Inventory>(initialData);
	const [currentStep, setCurrentStep] = useState(0);
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [loading, setLoading] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleNextStep = (e: any) => {
		e.preventDefault();
		setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
		return false;
	};

	const handlePreviousStep = (e: any) => {
		e.preventDefault();
		setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
		return false;
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		e.preventDefault();
		const {name, value} = e.target;
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			[name]: value,
		}));
	};

	const handlePurchaseDetailsInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		e.preventDefault();
		let {name, value}:any = e.target;
		if(name === "price") {
			value = parseInt(value)
		}
		// @ts-ignore
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			purchaseDetails: {
				...prevInventory.purchaseDetails,
				[name]: value,
			}
		}));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...inventory.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: newSizes,
			totalQuantity: calculateTotalQuantity(newSizes),
			stockLevel: determineStockLevel(calculateTotalQuantity(newSizes)),
			outOfStock: newSizes.length === 0 || calculateTotalQuantity(newSizes) === 0,
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = inventory.sizes.filter((_: any, i: any) => i !== index);
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: newSizes,
			totalQuantity: calculateTotalQuantity(newSizes),
			stockLevel: determineStockLevel(calculateTotalQuantity(newSizes)),
			outOfStock: newSizes.length === 0 || calculateTotalQuantity(newSizes) === 0,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			if(!inventory.name || !inventory.SKU || !inventory.brand) {
				setLoading(false);
				return setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Inventory field error",
					message: `Name, SKU and brand fields required`
				})
			}
			// if there's already an id
			// let's update this inventory object
			inventory.user = userStore.getUser()?.id as string
			let update: boolean = false;
			if(inventory.id) {
				inventory.updatedAt = new Date().getTime()
				update = true;
			} else {
				inventory.createdAt = new Date().getTime()
				inventory.updatedAt = new Date().getTime()
				inventory.id = generateUUID();
			}
			const func = update ? updateInventory.bind(this, inventory.id) : createInventory
			await func(inventory)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						if (update) {
							inventoryStore.updateInventory(res.data)
						} else {
							inventoryStore.addInventory([res.data])
						}
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Inventory ${update ? "Updated" : "Created"}`,
							message: `Inventory information successfully ${update ? "updated" : "created"}`
						})
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2e3);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating user object",
							// @ts-ignore
							message: res.error?.errorMessage | res.message
						})
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating user object",
						message: err.message
					})
				})
		} catch (error) {
			console.error('Error creating inventory:', error);
		} finally {
		}
	};

	const imageUploadHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result as string;
				// @ts-ignore
				setInventory((prevInventory: Inventory) => ({
					...prevInventory,
					imageUrl: base64String,
				}));
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<nav aria-label="Progress">
									<ol role="list"
									    className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
										{steps.map((step, stepIdx) => (
											<li key={step.name} className="relative md:flex md:flex-1">
												{currentStep > stepIdx ? (
													<a href="#" className="group flex w-full items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span
	                              className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-deepPurple group-hover:bg-brightPurple">
                                <CheckIcon aria-hidden="true" className="h-6 w-6 text-white"/>
                              </span>
                              <span
	                              className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-400">{step.name}</span>
                            </span>
													</a>
												) : currentStep === stepIdx ? (
													<a href="#" aria-current="step" className="flex items-center px-6 py-4 text-sm font-medium">
                            <span
	                            className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-deepPurple">
                              <span className="text-deepPurple">{step.id}</span>
                            </span>
														<span className="ml-4 text-sm font-medium text-deepPurple">{step.name}</span>
													</a>
												) : (
													<a href="#" className="group flex items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span
	                              className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                              </span>
                              <span
	                              className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                            </span>
													</a>
												)}

												{stepIdx !== steps.length - 1 ? (
													<>
														{/* Arrow separator for lg screens and up */}
														<div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
															<svg
																fill="none"
																viewBox="0 0 22 80"
																preserveAspectRatio="none"
																className="h-full w-full text-gray-300"
															>
																<path
																	d="M0 -2L20 40L0 82"
																	stroke="currentcolor"
																	vectorEffect="non-scaling-stroke"
																	strokeLinejoin="round"
																/>
															</svg>
														</div>
													</>
												) : null}
											</li>
										))}
									</ol>
								</nav>

								<form onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit().catch((err) => {
										console.log(err)
									});
								}}>
									{currentStep === 0 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Basic Info</h3>
											<div className="mt-2">
												<label htmlFor="name"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Name
												</label>
												<div className="mt-2">
													<input
														id="name"
														name="name"
														type="text"
														autoComplete="name"
														value={inventory.name}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="SKU"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													SKU
												</label>
												<div className="mt-2">
													<input
														id="SKU"
														name="SKU"
														type="text"
														autoComplete="sku"
														value={inventory.SKU}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="brand"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Brand
												</label>
												<div className="mt-2">
													<input
														id="brand"
														name="brand"
														type="text"
														autoComplete="brand"
														value={inventory.brand}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="color"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Color
												</label>
												<div className="mt-2">
													<input
														id="color"
														name="color"
														type="text"
														autoComplete="color"
														value={inventory.color}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="description"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Description
												</label>
												<div className="mt-2">
                          <textarea
	                          id="description"
	                          name="description"
	                          autoComplete="description"
	                          value={inventory.description}
	                          onChange={handleInputChange}
	                          className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                          rows={4}
                          />
												</div>
											</div>
											<div className="mt-3 relative">
												<div className="flex w-24 items-center relative justify-between">
													<label htmlFor="sizes"
													       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
														Sizes
													</label>
													<Switch
														checked={sizeType === 'EU'}
														onChange={handleToggleSizeType}
														className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
													>
														<span className="sr-only">Toggle Size Type</span>
														<span
															className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
													</Switch>
												</div>
												{
													openMenu && <Transition
														as={"div"}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<div
															className="absolute left-0 bottom-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
															<div className="py-1 max-h-60 overflow-y-auto">
																{Object.keys(productSizes).map((category) => (
																	<div key={category}>
																		<div>
																			<div
																				onClick={(e: any) => {
																					e.preventDefault();
																					handleCategoryClick(category)
																				}}
																				className={`inline-flex w-full px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																			>
																				{category.charAt(0).toUpperCase() + category.slice(1)}
																				<ChevronRightIcon aria-hidden="true"
																				                  className="-mr-1 h-5 w-5 text-gray-400"/>
																			</div>
																		</div>
																	</div>
																))}
															</div>
														</div>
													</Transition>
												}
												{
													openCategory &&
													<Transition
														as={"div"}
														enter="transition ease-out duration-100"
														enterFrom="transform opacity-0 scale-95"
														enterTo="transform opacity-100 scale-100"
														leave="transition ease-in duration-75"
														leaveFrom="transform opacity-100 scale-100"
														leaveTo="transform opacity-0 scale-95"
													>
														<div
															className="absolute left-[225px] -bottom-2.5 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
															<div className="py-1">
																{productSizes[openCategory][sizeType.toLowerCase()].map((sizeOption) => (
																	<div key={sizeOption}>
																		<button
																			type="button"
																			onClick={(e) => {
																				e.preventDefault();
																				handleSizeChange(currentSizeIndex, 'size', sizeOption)
																			}}
																			className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																		>
																			{sizeOption}
																		</button>
																	</div>
																))}
															</div>
														</div>
													</Transition>
												}
												{inventory.sizes.map((size, index) => (
													<div key={index} className="flex items-center mt-1">
														<div className="relative inline-block text-left w-1/2 mr-2">
															<div>
																<div
																	onClick={() => {
																		setCurrentSizeIndex(index)
																		setOpenMenu(!openMenu)
																	}}
																	className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
																	{size.size || 'Select Size'}
																	<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
																</div>
															</div>
														</div>
														<input
															type="number"
															name={`quantity-${index}`}
															value={size.quantity}
															onChange={(e) => {e.preventDefault(); handleSizeChange(index, 'quantity', parseInt(e.target.value))}}
															className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
															placeholder="Quantity"
														/>
														<button
															type="button"
															onClick={() => handleRemoveSize(index)}
															className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
														>
															<XCircleIcon className="h-6 w-6"/>
														</button>
														<button
															type="button"
															onClick={handleAddSize}
															className="text-blue-500 hover:text-blue-700 ml-2"
														>
															<PlusCircleIcon className="h-6 w-6"/>
														</button>
													</div>
												))}
											</div>
											<div className="mt-2">
												<div className="col-span-full">
													<label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-white">
														Product Images
													</label>
													<div
														className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-400 dark:border-white/25 px-6 py-10">
														<div className="text-center">
															<PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-500"/>
															<div className="mt-4 flex text-sm leading-6 text-gray-400">
																<label
																	htmlFor="file-upload"
																	className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
																>
																	<span
																		className={"px-2 py-2 underline !bg-transparent text-deepPurple rounded text-sm"}>Upload images</span>
																	<input accept={"image/*"} id="file-upload" onChange={imageUploadHandler} name="imageUrl" type="file" className="sr-only"/>
																</label>
																<p className="pl-1">or drag and drop</p>
															</div>
															<p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									)}

									{currentStep === 1 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Purchase Details</h3>
											<div className="mt-2">
												<label htmlFor="price"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Price
												</label>
												<div className="mt-2">
													<input
														id="price"
														name="price"
														type="number"
														autoComplete="price"
														value={inventory.purchaseDetails?.price || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="shipping"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Shipping
												</label>
												<div className="mt-2">
													<input
														id="shipping"
														name="shipping"
														type="text"
														autoComplete="shipping"
														value={inventory.purchaseDetails?.shipping || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="vatPercentage"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													VAT (%)
												</label>
												<div className="mt-2">
													<input
														id="vatPercentage"
														name="vatPercentage"
														type="number"
														autoComplete="vat-percentage"
														value={inventory.purchaseDetails?.vatPercentage || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="purchasePlace"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Purchase Place
												</label>
												<div className="mt-2">
													<input
														id="purchasePlace"
														name="purchasePlace"
														type="text"
														autoComplete="purchase-place"
														value={inventory.purchaseDetails?.purchasePlace || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="purchaseDate"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Purchase Date
												</label>
												<div className="mt-2">
													<input
														id="purchaseDate"
														name="purchaseDate"
														type="date"
														autoComplete="purchase-date"
														value={inventory.purchaseDetails?.purchaseDate || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="orderNumber"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Order Number
												</label>
												<div className="mt-2">
													<input
														id="orderNumber"
														name="orderNumber"
														type="text"
														autoComplete="order-number"
														value={inventory.purchaseDetails?.orderNumber || ''}
														onChange={handlePurchaseDetailsInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
										</div>
									)}

									{currentStep === 2 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Classification
												Info</h3>
											<div className="mt-2">
												<label htmlFor="category"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Category
												</label>
												<div className="mt-2">
													<select
														id="category"
														name="category"
														autoComplete="category"
														value={inventory.category}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													>
														{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
															<option key={key} value={Categories[key as keyof typeof Categories]}>
																{key}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="source"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Source
												</label>
												<div className="mt-2">
													<select
														id="source"
														name="source"
														autoComplete="source"
														value={inventory.source}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													>
														{Object.keys(Sources).filter(key => isNaN(Number(key))).map((key) => (
															<option key={key} value={Sources[key as keyof typeof Sources]}>
																{key}
															</option>
														))}
													</select>
												</div>
											</div>
											<div className="mt-2">
												<label htmlFor="collectionId"
												       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Add to List / Create List
												</label>
												<div className="mt-2">
													<input
														id="collectionId"
														name="collectionId"
														type="text"
														autoComplete="collection-id"
														value={inventory.collectionId}
														onChange={handleInputChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="mt-2 flex items-center">
												<input
													id="autoList"
													name="autoList"
													type="checkbox"
													checked={inventory.autoList}
													onChange={(e) => setInventory({...inventory, autoList: e.target.checked})}
													className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:text-white"
												/>
												<label htmlFor="autoList"
												       className="ml-2 block text-sm font-medium leading-6 text-gray-900 dark:text-white">
													Auto List
												</label>
											</div>
										</div>
									)}

									<div className="mt-4 flex justify-between">
										<button
											type="button"
											disabled={loading || currentStep === 0}
											onClick={handlePreviousStep}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
										>
											Previous
										</button>

										{currentStep < steps.length - 1 ? (
											<button
												type="button"
												onClick={handleNextStep}
												className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												Next
											</button>
										) : (
											<button
												type="submit"
												disabled={loading}
												className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												{loading ? (
													<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
													     fill="none" viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														        strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor"
														      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
													</svg>
												) : (
													'Submit'
												)}
											</button>
										)}
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{ notificationComponent }
		</Transition>
	);
};

export default InventoryForm;
