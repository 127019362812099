import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import EmptyComponent from "../components/Empty";
import { observer } from 'mobx-react-lite';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisHorizontalIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { inventoryStore } from '../state/Inventory';
import noInventoryImage from '../images/no-inventory-image.png';
import ListingImage from '../components/Image';
import {
	Categories,
	Inventory as InventoryType,
	MarketplacePlatform, Sources, Statuses,
	statusesToJSON, StockLevel,
	stockLevelToJSON
} from "../utils/types";
import ListingImageCloud from "../components/ListingImageCloud";
import Tooltip from "../components/Tooltip";
import InventoryItem from "../components/InventoryItem";
import {statuses} from "../utils/data";
import {deleteInventory, getInventories} from "../api/inventories";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import Loader from "../components/Loading";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const SortableHeader = ({ field, label, sortField, sortOrder, onSort }:any) => {
	return (
		<th
			scope="col"
			className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter cursor-pointer"
			onClick={() => onSort(field)}
		>
			<div className="flex items-center">
				{label}
				{sortField === field && (
					sortOrder === 'asc' ? (
						<ChevronUpIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white" />
					) : (
						<ChevronDownIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white" />
					)
				)}
			</div>
		</th>
	);
};

const Inventory = observer(({
	                            handleCreateInventory,
	                            handleCreateCollection,
	                            handleCreateListing
                            }: {
	handleCreateInventory: (inventory?:InventoryType) => void;
	handleCreateCollection: () => void;
	handleCreateListing: () => void;
}) => {
	const checkbox = useRef(null);
	const [checked, setChecked] = useState(false);
	const [loadInventories, setLoadInventories] = useState(false);
	const [currentInventory, setCurrentInventory] = useState(null);
	const [indeterminate, setIndeterminate] = useState(false);
	const [selectedItems, setSelectedItems] = useState<InventoryType[]>([]);
	const [deletingItems, setDeletingItems] = useState<InventoryType[]>([]);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleSort = (field:string) => {
		if (inventoryStore.sortOption.field === field) {
			inventoryStore.setSortOption({...inventoryStore.sortOption, direction: inventoryStore.sortOption.direction === 'asc' ? 'desc' : 'asc'});
		} else {
			inventoryStore.setSortOption({field, direction: "asc"});
		}
	};

	useLayoutEffect(() => {
		const isIndeterminate = selectedItems.length > 0 && selectedItems.length < inventoryStore.filteredAndSortedInventory.length;
		setChecked(selectedItems.length === inventoryStore.filteredAndSortedInventory.length);
		setIndeterminate(isIndeterminate);
		if (checkbox.current) {
			// @ts-ignore
			checkbox.current.indeterminate = isIndeterminate;
		}
	}, [selectedItems, inventoryStore.filteredAndSortedInventory.length]);

	useEffect(() => {
		if(inventoryStore.inventory.length) return;
		setLoadInventories(true);
		getInventories()
			.then((res:any) => {
				if(!res.isError) {
					inventoryStore.setInventory(res.data)
				}
				setLoadInventories(false);
			})
			.catch((err:any) => {
				console.log(err);
				setLoadInventories(false);
			})
	}, []);

	const inventoryItemClick = (item:any) => {
		setCurrentInventory(item);
	};

	const inventoryItemClickPopupOnClose = () => {
		setCurrentInventory(null);
	}

	const editClick = (inventory:InventoryType) => {
		handleCreateInventory(inventory)
		setCurrentInventory(null);
	}

	const deleteClick = async (inventory:InventoryType) => {
		setDeletingItems([...deletingItems, inventory]);
		await deleteInventoryRequest(inventory.id)
		setDeletingItems(deletingItems.filter((dit) => dit !== inventory));
		setCurrentInventory(null);
	}

	const deleteInventoryRequest = (id: string) => {
		return deleteInventory(id).then((res:any) => {
			console.log(res);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Inventory Removed`,
				message: `Inventory object successfully removed`
			});
			inventoryStore.deleteInventory(id)
		}).catch((err:any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Inventory Delete error`,
				message: err.message
			})
		});
	}

	const deleteSelectedItems = async (e: any) => {
		e.preventDefault();
		try {
			setDeletingItems(selectedItems)
			// Wait for all delete operations to complete
			await Promise.all(selectedItems.map((inv) => deleteInventory(inv.id)));
			// Update the inventory store and clear selected items
			inventoryStore.setInventory(inventoryStore.filteredAndSortedInventory.filter((item: InventoryType) => !selectedItems.includes(item)));
			setSelectedItems([]);
		} catch (error) {
			console.error("Error deleting items:", error);
			// Handle error if needed
		}
	};

	function toggleAll() {
		setSelectedItems(checked || indeterminate ? [] : inventoryStore.filteredAndSortedInventory);
		setChecked(!checked && !indeterminate);
		setIndeterminate(false);
	}

	const totalCost = selectedItems.reduce((acc, item:InventoryType) => acc + (item.purchaseDetails!.price * item.totalQuantity), 0).toFixed(2);

	if (loadInventories) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader />
			</div>
		);
	}

	if (inventoryStore.inventory.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<EmptyComponent
					imageSrc={noInventoryImage}
					headerText={"Inventory"}
					descriptionText={"You have no inventory items"}
					actionText={"Create New Inventory"}
					onActionClick={handleCreateInventory}
				/>
			</div>
		);
	}

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			{notificationComponent}
			{currentInventory && <InventoryItem del={deletingItems.includes(currentInventory)} inventory={currentInventory} onClose={inventoryItemClickPopupOnClose} deleteClick={deleteClick} editClick={editClick} />}
			<div className="sm:flex sm:items-center">
				<div className="flex justify-between items-center w-full sm:flex-auto">
					<h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-white">Inventory</h1>
					<div className={"space-x-4"}>
						<button
							disabled={!selectedItems.length}
							onClick={() => {
								console.log("exporting inventory")
							}}
							className={`rounded-md ${!selectedItems.length && "grayscale"} bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
						>
							Export
						</button>
						<button
							disabled={!selectedItems.length}
							onClick={() => {
								console.log("exporting inventory")
							}}
							className={`rounded-md ${!selectedItems.length && "grayscale"} bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
						>
							List
						</button>
						<button
							onClick={() => {
								handleCreateInventory(undefined)
							}}
							className="rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							Create New Inventory
						</button>
					</div>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full min-h-[80vh] py-2 align-middle sm:px-6 lg:px-8">
						<div className="relative flex justify-between w-full">
							<div>
								{selectedItems.length > 0 && (
									<div
										className="left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
										<span
											className="text-sm font-medium text-gray-900 dark:text-white">{selectedItems.length} selected</span>
										<span className="text-sm font-medium text-gray-900 dark:text-white">${totalCost} total cost</span>
									</div>
								)}
							</div>
							<div className="flex w-[80%] items-center justify-end space-x-8 mb-4">
								<div className="flex items-center space-x-4">
									{/*<input*/}
									{/*	type="text"*/}
									{/*	placeholder="Search"*/}
									{/*	value={filter}*/}
									{/*	onChange={(e) => {inventoryStore.setFilter(e.target.value)}}*/}
									{/*	className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"*/}
									{/*/>*/}
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedCategory(e.target.value)}}
										value={inventoryStore.selectedCategory}
										className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Categories: All</option>
										{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={Categories[key as keyof typeof Categories]}>
												{key}
											</option>
										))}
									</select>
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedStatus(e.target.value)}}
										value={inventoryStore.selectedStatus}
										className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Status: All</option>
										{Object.keys(Statuses).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={Statuses[key as keyof typeof Statuses]}>
												{key}
											</option>
										))}
									</select>
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedStockLevel(e.target.value) }}
									        value={inventoryStore.selectedStockLevel}
									        className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Stock Level: All</option>
										{Object.keys(StockLevel).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={StockLevel[key as keyof typeof StockLevel]}>
												{key}
											</option>
										))}
									</select>
								</div>
								<button
									type="button"
									onClick={() => {
										// inventoryStore.setFilter('');
										inventoryStore.setSelectedStatus('');
										inventoryStore.setSelectedPlatform('');
										inventoryStore.setSelectedCategory('');
										inventoryStore.setSelectedStockLevel('');
									}}
									className="block rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
								>
									Clear
								</button>
							</div>
						</div>
						<div className="relative">
							{selectedItems.length > 0 && (
								<div
									className="absolute left-14 top-0 z-[20] flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
									<button
										type="button"
										className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										Bulk edit
									</button>
									{/*@ts-ignore*/}
									<button onClick={deleteSelectedItems}
									        disabled={Boolean(deletingItems.length)}
									        type="button"
									        className="inline-flex items-center !text-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										{Boolean(deletingItems.length) ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
										"Delete all"
											)
										}
									</button>
								</div>
							)}
							<table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-gray-700">
								<thead>
								<tr>
									<th scope="col" className="relative px-7 sm:w-12 sm:px-6">
										<input
											type="checkbox"
											className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
											ref={checkbox}
											checked={checked}
											onChange={toggleAll}
										/>
									</th>
									<SortableHeader
										field="name"
										label="Name"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="SKU"
										label="SKU"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="purchaseDetails.price"
										label="Price"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="totalQuantity"
										label="Quantity"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
										Listings
									</th>
									<SortableHeader
										field="color"
										label="Color"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
										Status
									</th>
									<SortableHeader
										field="purchaseDetails.purchaseDate"
										label="Purchase Date"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
										Actions
									</th>
								</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 dark:divide-gray-800 bg-white dark:bg-gray-900">
								{inventoryStore.filteredAndSortedInventory.map((item: InventoryType) => (
									// @ts-ignore
									<tr key={item.id}
									    className={selectedItems.includes(item) ? 'bg-gray-50 dark:bg-gray-800' : '' + ' cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800/10'}>
										<td className="relative px-7 sm:w-12 sm:px-6">
											{/*// @ts-ignore*/}
											{selectedItems.includes(item) && (
												<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"/>
											)}
											{/*// @ts-ignore*/}
											<input checked={selectedItems.includes(item)}
											       type="checkbox"
											       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
											       value={item.id}
											       onChange={(e) =>
												       // @ts-ignore*
												       setSelectedItems(e.target.checked ? [...selectedItems, item] : selectedItems.filter((p) => p !== item))
											       }
											/>
										</td>
										{/*// @ts-ignore*/}
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className={classNames('whitespace-nowrap py-4 pr-3 text-sm font-medium', selectedItems.includes(item) ? 'text-indigo-600' : 'text-gray-900 dark:text-white')}>
											<div className="flex items-center gap-x-4">
												<ListingImage title={item.SKU} imageUrl={item.imageUrl}/>
												<div
													className="truncate text-sm text-black dark:text-white font-medium leading-6">{item.name}</div>
											</div>
										</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.SKU}</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">${item.purchaseDetails!.price.toFixed(2)}</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.totalQuantity}</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
											<ListingImageCloud
												marketplaces={[MarketplacePlatform.EBAY, MarketplacePlatform.AMAZON, MarketplacePlatform.SHOPIFY]}/>
										</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.color}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
											<div className={"inline-flex space-x-2"}>
												<Tooltip text={`status: ${statusesToJSON(item.status)}`}>
													<div
														className={classNames(statuses[statusesToJSON(item.status)], 'flex-none rounded-full p-1')}>
														<div className="h-4 w-4 rounded-full bg-current"/>
													</div>
												</Tooltip>
												<Tooltip text={`stockLevel: ${stockLevelToJSON(item.stockLevel)}`}>
													<div
														className={classNames(statuses[stockLevelToJSON(item.stockLevel)], 'flex-none rounded-full p-1')}>
														<div className="h-4 w-4 rounded-full bg-current"/>
													</div>
												</Tooltip>
											</div>
										</td>
										<td
											className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.purchaseDetails?.purchaseDate}</td>
										<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
											<Menu as="div" className="relative inline-block text-left">
												<div>
													{deletingItems.includes(item) ? (
														<svg className="animate-spin h-5 w-5 text-deepPurple" xmlns="http://www.w3.org/2000/svg" fill="none"
														     viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
															        strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor"
															      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
														</svg>
													) : (
														<Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
															<span className="sr-only">Open options</span>
															<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
														</Menu.Button>
													)}
												</div>
												<Transition
													as={React.Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items
														className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		inventoryItemClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	View
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		editClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	Edit
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		deleteClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	Delete
																</button>
															)}
														</Menu.Item>
													</Menu.Items>
												</Transition>
											</Menu>
										</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});

export default Inventory;
