import React, { useState } from 'react';

interface ListingImageProps {
	title: string;
	imageUrl: string;
	className?: string;
}

const ListingImage: React.FC<ListingImageProps> = ({ title, imageUrl, className }) => {
	const defaultImage = encodeURI(`https://placehold.co/150x200/C4B5FD/7B5EFF?text=${title}&font=oswald`); // Replace with your default image URL
	const [imgSrc, setImgSrc] = useState(imageUrl || defaultImage);
	if (!className) { className = "h-12 w-12" }

	const handleError = () => {
		setImgSrc(defaultImage);
	};

	return (
		<img
			alt={title}
			src={imgSrc}
			onError={handleError}
			className={`${className} flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10`}
		/>
	);
};

export default ListingImage;
