import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Listing, MarketplacePlatform } from '../utils/types';
import { PhotoIcon } from '@heroicons/react/24/outline';

const initialListing: Listing = {
	platform: MarketplacePlatform.EBAY,
	id: '',
	user: '',
	title: '',
	description: '',
	price: 0,
	currencyCode: '',
	quantity: 0,
	imageUrl: '',
	category: '',
	condition: '',
	seller: '',
	location: '',
	shipping: undefined,
	scheduleDateTime: 0,
	createdAt: Date.now(),
	updatedAt: Date.now(),
	status: '',
	inventoryId: '',
};

const ListingForm = ({ initialData = initialListing, onClose }: { initialData?: Listing, onClose: () => void; }) => {
	const [listing, setListing] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setListing((prevListing) => ({
			...prevListing,
			[name]: value,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log('Listing created:', listing);
			setShowDialog(false);
		} catch (error) {
			console.error('Error creating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => { setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									Create Listing
								</Dialog.Title>
								<form onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit();
								}}>
									<div className="mt-4">
										<label htmlFor="platform"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Marketplace
										</label>
										<div className="mt-2">
											<input
												id="platform"
												name="platform"
												type="text"
												autoComplete="platform"
												value={listing.platform}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="description"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Description
										</label>
										<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={listing.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Price
										</label>
										<div className="mt-2">
											<input
												id="price"
												name="price"
												type="number"
												autoComplete="price"
												value={listing.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="col-span-full">
										<label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-white">
											Cover photo
										</label>
										<div
											className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-400 dark:border-white/25 px-6 py-10">
											<div className="text-center">
												<PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-500"/>
												<div className="mt-4 flex text-sm leading-6 text-gray-400">
													<label
														htmlFor="file-upload"
														className="relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500"
													>
														<span className={"px-2 py-2 underline !bg-transparent text-deepPurple rounded text-sm"}>Upload a files</span>
														<input id="file-upload" multiple={true} name="file-upload" type="file" className="sr-only"/>
													</label>
													<p className="pl-1">or drag and drop</p>
												</div>
												<p className="text-xs leading-5 text-gray-400">PNG, JPG, GIF up to 10MB</p>
											</div>
										</div>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Listing'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ListingForm;
